.time-button {
  float: left;
  margin: 0 5px 0 0;
  background-color: none;
  height: 40px;
  position: relative;
}

.time-button label,
.time-button input {
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 10px;
  border: 1px solid #f5f5f7;
  background-color: #f5f5f7;
  height: 45px;
  /* cursor: pointer; */
}

.time-button input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
  cursor: pointer;
}

.time-button input[type="radio"]:checked + label {
  background: #1976d2;
  color: #fff;
  border-radius: 4px;
  /* cursor: pointer; */
}

.time-button label {
  cursor: pointer;
  z-index: 90;
  line-height: 1.8em;
}

.patient-detail-img {
  max-width: 230px;
}
@media (max-width: 992px) {
  .patient-detail-img {
    max-width: 210px;
  }
}
@media (max-width: 400px) {
  .appointmentContainer {
    padding: 0px;
  }
}
.time-button:hover {
  cursor: pointer;
}

/* Feedback Form */

.viewCertificate-btn {
  padding: 6px 25px;
  border: none;
  background-color: #00bf63;
  color: #fff;
  border-radius: 5px;
}
textarea {
  resize: none;
}
