.container {
  padding-left: 0;
  padding-right: 0;
}

.why-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  background-color: transparent;
  /* border: 1px solid #616161; */
  gap: 15px 0;
  padding: 15px 10px;
}
.why-card > img {
  width: 50%;
}
.why-card > ul > li {
  font-size: 28px;
}
.why > svg {
  position: absolute;
  bottom: -10vw;
  z-index: 99;
}
.whyDiv {
  padding: 30px;
  padding-bottom: 100px;
  z-index: 99;
  position: relative;
}
.why {
  background-color: rgb(2, 17, 56);
  width: 100%;
  /* display: flex;
    justify-content: center; */
  padding: 30px 0;
  position: relative;
  /* margin-top: 80px; */
}
.sun {
  position: absolute;
  width: 100px;
  height: 100px;
  top: -20px;
  left: 50px;
  z-index: 9999;
  transform: translatey(0px);
  filter: drop-shadow(5px 5px 5px rgba(255, 255, 0, 0.8));
  animation: float 6s ease-in-out infinite;
}

.whyContentDiv {
  margin-top: 30px;
  color: #f5f5f7;
  /* font-family: "Baloo 2", cursive; */
  font-size: 24px;
  font-weight: 300;
}

.whyContentDiv ul li {
  margin: 15px 0;
  font-family: "Baloo 2", cursive;
}

.whyContentDiv img {
  float: right;
  width: 50%;
  margin-left: 20px;
  /* margin-bottom: 5px; */
  border-radius: 8px;
}

.container.whyDiv > h3 {
  font-size: 35px;
  margin-bottom: 50px;
  font-family: "Baloo 2", cursive;
  color: #f5f5f7;
  text-align: center;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@media (max-width: 992px) {
  .why {
    padding: 0px 0;
  }
}
/* .whyDiv > h1 {
  font-size: 48px;
  text-align: center;
  color: #fff;
} */
/* .container.whyDiv > h3 {
  font-size: 24px;
  color: #f5f5f7;
  text-align: center;
} */

@media screen and (max-width: 768px) {
  .whyContentDiv {
    margin-top: 30px;
    color: #f5f5f7;
    font-family: "Baloo 2", cursive;
    font-size: 16px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .whyContentDiv ul li {
    margin: 10px 0;
    padding: 0 10px;
  }

  .whyContentDiv img {
    float: right;
    width: 90%;
    margin-left: 0px;
    margin: 0 auto;
    border-radius: 8px;
  }

  .container.whyDiv > h3 {
    font-size: 24px;
    color: #f5f5f7;
    text-align: center;
    margin-bottom: 15px;
  }
}

@media (max-width: 992px) {
  /* .whyDiv > h1 {
    font-size: 28px;
  } */
  /* .container.whyDiv > h3 {
    font-size: 24px;
  } */
}
@media (max-width: 576px) {
  /* .whyDiv > h1 {
    font-size: 24px;
  } */
  /* .container.whyDiv > h3 {
    font-size: 24px;
  } */
}

@media (max-width: 576px) {
  .whyDiv {
    padding: 20px 15px;
  }
  .why-card > img {
    width: 30%;
  }
}
@media (max-width: 992px) {
  .why-card > img {
    width: 40%;
  }
  .why-card > ul > li {
    font-size: 24px;
  }
}
@media (max-width: 576px) {
  .why-card {
    gap: 5px 0;
  }
  .why-card > ul > li {
    /* padding-top: 10px; */
    font-size: 22px;
  }
  .why-card > p {
    font-size: 16px;
  }
}
.why-left img {
  width: 100%;
  border-radius: 5px;
}
.why-right {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  color: #fff;
  align-items: flex-start;
  /* justify-content: center; */
}
/* .why-right > h3 {
  font-size: 32px;
  color: #fff;
  font-family: "Baloo 2", cursive;
} */
.why-right > ul > li {
  font-size: 24px;
  font-weight: 300;
  color: #f5f5f7;
  font-family: "Baloo 2", cursive;
}

@media (max-width: 992px) {
  /* .why-right > h3 {
    font-size: 28px;
  } */
  .why-right > ul > li {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  /* .why-right > h3 {
    font-size: 24px;
  } */
  .why-right > ul > li {
    font-size: 16px;
  }
}
