.who {
  width: 100%;
  /* display: flex;
    justify-content: center; */
  padding: 80px 0 30px 0;
  position: relative;
  background-color: #021138;
}
.star {
  position: absolute;
  width: 100px;
  z-index: 9999;
  top: 0px;
  right: 50px;
  transform: translatey(0px);
  filter: drop-shadow(5px 5px 5px rgba(255, 255, 0, 0.5));
  animation: float 6s ease-in-out infinite;
}

.whoContentDiv {
  margin-top: 30px;
  color: #f5f5f7;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.whoContentDiv img {
  width: 40%;
  margin-right: 20px;
  /* margin-left: 20px; */
  /* margin-bottom: 5px; */
  border-radius: 8px;
}

.whoContentDiv h5 {
  font-family: "Baloo 2", cursive;
  font-size: 24px;
  font-weight: 300;
}

.container.whoDiv > h3 {
  font-size: 35px;
  margin-bottom: 50px;
  font-family: "Baloo 2", cursive;
  color: #f5f5f7;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .whoContentDiv {
    margin-top: 30px;
    color: #f5f5f7;

    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
  }

  .whoContentDiv img {
    width: 90%;
    margin-right: 0px;
    /* margin-left: 20px; */
    margin-bottom: 15px;
    border-radius: 8px;
  }

  .whoContentDiv h5 {
    font-family: "Baloo 2", cursive;
    font-size: 16px;
    font-weight: 300;
    padding: 0px 15px;
  }

  .container.whoDiv > h3 {
    font-size: 24px;
    margin-bottom: 20px;
    font-family: "Baloo 2", cursive;
    color: #f5f5f7;
    text-align: center;
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
.who > svg {
  position: absolute;
  top: -12vw;
  z-index: 9;
}
.whoDiv {
  padding: 40px;
  z-index: 99;
  position: relative;
  /* background-color: #fff5f4ac; */
}
@media (max-width: 576px) {
  .whoDiv {
    padding: 20px 15px;
  }
}
.who-left {
  display: flex;
  justify-content: center;
}
.who-right {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  color: #fff;
  align-items: flex-start;
  /* justify-content: center; */
}
.who-left > img {
  width: 100%;
  border-radius: 5px;
}
.who-right > h3 {
  font-size: 32px;
  font-family: "Baloo 2", cursive;
}
.who-right > h5 {
  font-size: 24px;
  font-weight: 300;
  color: #f5f5f7;
  font-family: "Baloo 2", cursive;
}
@media (max-width: 992px) {
  .who-right > h3 {
    font-size: 28px;
  }
  .who-right > h5 {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .who-right > h3 {
    font-size: 24px;
  }
  .who-right > h5 {
    font-size: 16px;
  }
}
