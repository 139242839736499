body {
    background: rgb(99, 39, 120)
}

.form-control:focus {
    box-shadow: none;
    border-color: #00be60
}

.profile-button {
    background: #00be60;
    box-shadow: none;
    border: none
}



.back:hover {
    color: #00be60;
    cursor: pointer
}

.labels {
    font-size: 11px
}

.add-experience:hover {
    background: #00be60;
    color: #fff;
    cursor: pointer;
    border: solid 1px #00be60
}

.bio{
    height: 65px;
    padding: 15px ;
}
.remove-btn{
    padding: 8px 25px;
    border: none;
    background-color: grey;
    border-radius: 5px;
    color: #fff;
}